<template>
  <div :class="{ dark: dark }">
    <section class="section">
      <div class="container">
        <el-divider v-if="!dark"></el-divider>
        <nav class="level">
          <div class="level-left">
            <router-link :to="{ name: 'home' }" class="level-item is-size-6">
              <span class="has-text-weight-bold has-font-slab">Floom</span>
            </router-link>
            <router-link :to="{ name: 'launch' }" class="level-item">Launch</router-link>
            <a href="https://blog.floom.app" target="_blank" class="level-item">Blog</a>
            <a href="https://docs.floom.app" target="_blank" class="level-item">API Docs</a>
            <a href="mailto:rob@floom.app" target="_blank" class="level-item">Contact Us</a>
            <a
              href="https://www.iubenda.com/terms-and-conditions/52278240"
              target="_blank"
              class="level-item"
            >Terms and Conditions</a>
          </div>
        </nav>
        <el-divider v-if="dark"></el-divider>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    dark: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.dark {
  background-color: #2a273d;
  .level-item {
    color: white;
  }
}
.level-item {
  color: #4a4a4a;
  margin-left: 30px;
  font-weight: 400;
  font-size: 0.85em;
  text-transform: uppercase;
}
</style>
