/* eslint-disable */
const awsmobile = {
  dev: {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:14c94253-c863-4143-93d6-0e6be8f87440",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MQxFW29T7",
    "aws_user_pools_web_client_id": "4so4sbfaotmufdj4bjivl2e3g7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://gjfnruslwrbrhkycj2pja6xxge.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "floom-vault-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
  },
  NONE: {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:771e555f-e16b-4d81-b20c-da965486e8b3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9rojlLc2r",
    "aws_user_pools_web_client_id": "61dpniecnr6d797bgcb93j2s0j",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://xdkywe3szbf4hhgcbgnk4bo7ry.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "floom-vault",
    "aws_user_files_s3_bucket_region": "us-east-1"
  }
}

export default awsmobile;
