import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Floom: A better way to buy',
        metatags: {
          'og:title': 'Floom: A better way to buy',
          'og:image': 'https://storage.floom.app/file/e67d0a8c-1ccf-4cd9-aea7-65cbd0cf0bd3/image.png',
          'og:description': 'The best products from the web, all in one place.',
          keywords: 'SaaS, software, social media',
        },
      },
    },
    {
      path: '/account',
      name: 'account',
      meta: {
        requiresAuth: true,
        title: 'Your Floom Account',
      },
      component: () => import('./views/Account.vue'),
    },
    {
      path: '/market',
      name: 'market',
      meta: {
        requiresAuth: true,
        title: 'Floom Market',
      },
      component: () => import('./views/Market.vue'),
    },
    {
      path: '/launch',
      name: 'launch',
      meta: {
        requiresAuth: false,
        title: 'Launch with Floom',
        metatags: {
          'og:title': 'Floom: A better way to launch',
          'og:image': 'https://storage.floom.app/file/43c55b6c-e9bb-4907-be54-3d0582320904/image.png',
          'og:description': "Floom handles authentication, billing, and subscriptions so you don't have to.",
          keywords: 'SaaS, software, social media',
        },
      },
      component: () => import('./views/Launch.vue'),
    },
    {
      path: '/new-service',
      name: 'serviceNew',
      meta: {
        requiresAuth: true,
        adminOnly: true,
        title: 'Create a New Service',
      },
      component: () => import('./views/ServiceNew.vue'),
    },
    {
      path: '/edit-service/:id',
      name: 'serviceEdit',
      meta: {
        requiresAuth: true,
        vendorOnly: true,
        title: 'Edit Your Service',
      },
      component: () => import('./views/ServiceEdit.vue'),
    },
    {
      path: '/service/:id',
      name: 'service',
      meta: {
        requiresAuth: false,
      },
      component: () => import('./views/Service.vue'),
    },
    // {
    //   path: '/plans/:id',
    //   name: 'plans',
    //   meta: { requiresAuth: false },
    //   component: () => import('./views/Plans.vue'),
    // },
    {
      path: '/seller/:id',
      name: 'seller',
      meta: {
        requiresAuth: false,
      },
      component: () => import('./views/Seller.vue'),
    },
    {
      path: '/services',
      name: 'services',
      meta: {
        requiresAuth: true,
      },
      component: () => import('./views/Services.vue'),
    },
    {
      path: '/analytics',
      name: 'analytics',
      meta: {
        requiresAuth: true,
        vendorOnly: true,
        title: 'Your Analytics',
      },
      component: () => import('./views/Analytics.vue'),
    },
    {
      path: '/order/:id',
      name: 'order',
      meta: {
        requiresAuth: true,
      },
      component: () => import('./views/Requisition.vue'),
    },
    {
      path: '/orders',
      name: 'orders',
      meta: {
        requiresAuth: true,
      },
      component: () => import('./views/Requisitions.vue'),
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      meta: {
        requiresAuth: true,
      },
      component: () => import('./views/Subscriptions.vue'),
    },
    {
      path: '/subscription/:id',
      name: 'subscription',
      meta: {
        requiresAuth: true,
      },
      component: () => import('./views/Subscription.vue'),
    },
    {
      path: '/seller-orders',
      name: 'sellerOrders',
      meta: {
        requiresAuth: true,
        vendorOnly: true,
      },
      component: () => import('./views/VendorRequisitions.vue'),
    },
    {
      path: '/seller-subscriptions',
      name: 'sellerSubscriptions',
      meta: {
        requiresAuth: true,
        vendorOnly: true,
      },
      component: () => import('./views/VendorSubscriptions.vue'),
    },
    {
      path: '/wavve-templates',
      name: 'wavveTemplates',
      meta: {
        requiresAuth: false,
        vendorOnly: false,
      },
      component: () => import('./views/WavveTemplates.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      meta: {
        requiresAuth: false,
        vendorOnly: false,
      },
      component: () => import('./views/PrivacyPolicy.vue'),
    },
    {
      path: '/terms-and-conditions',
      name: 'termsAndConditions',
      meta: {
        requiresAuth: false,
        vendorOnly: false,
      },
      component: () => import('./views/TermsAndConditions.vue'),
    },
    {
      path: '/auth/:slug?',
      name: 'auth',
      meta: {
        title: 'Floom',
      },
      component: () => import('./views/Auth.vue'),
      props: {
        class: 'authenticator container has-text-centered',
      },
    },
    {
      path: '/no-access',
      name: 'noAccess',
      meta: {
        title: 'No Access',
      },
      component: () => import('./views/NoAccess.vue'),
    },
    {
      path: '*',
      name: 'PageNotFound',
      meta: {
        title: 'Page Not Found',
      },
      component: () => import('./views/PageNotFound.vue'),
    },
    {
      path: '/blog/:slug?',
      beforeEnter(to) {
        const { fullPath } = to
        window.location = `https://blog.floom.app${fullPath}`
      },
    },
  ],
})
