import uuidv4 from 'uuid/v4'

export const stringIsJson = (str) => {
  try {
    if (typeof (str) === 'string' && str.length === 0) {
      return false
    }
    JSON.parse(str);
    return true
  } catch (e) {
    return false
  }
}

export const generateUuid = () => uuidv4()

export const isUuidv4 = (str) => {
  const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
  return str.match(v4)
}

export const getFileType = (url) => {
  const extRegex = /(?:\.([^.]+))?$/
  const ext = extRegex.exec(url)[1]
  if (['mp3', 'wav'].includes(ext)) {
    return 'audio'
  }
  if (['png', 'jpg', 'jpeg', 'gif'].includes(ext)) {
    return 'image'
  }
  if (['mp4'].includes(ext)) {
    return 'video'
  }
  return null
}

export const getMetatagAttribute = (tag) => {
  const doesTagUseProperty = {
    'og:points': true,
    'og:video': true,
    'og:video:type': true,
    'og:video:height': true,
    'og:video:width': true,
    'og:audio': true,
    'og:audio:title': true,
    'og:audio:artist': true,
    'og:audio:album': true,
    'og:audio:type': true,
  }
  return doesTagUseProperty[tag] ? 'property' : 'name'
}
