const tags = {
  popular: 'popular',
  video: 'video',
  fun: 'fun',
  podcast: 'podcast',
  social: 'social',
}

/* eslint-disable */
export const featuredServices = [
  {
    name: 'One-Click Tweet-to-Video',
    id: 'a35d07fb-4ae8-4b58-b665-0344de6a885b',
    slug: 'tweet-to-video-premium',
    vendor: 'Squawk.dev',
    thumbnail: 'garyveetweet.png',
    videoThumbnail: 'products/tweet-to-video-blue.mp4',
    cost: 395,
    monthlyCost: 995,
    live: true,
    description:
      'Screenshots of Tweets are so 2019. Give you Instagram a touch of special with a video of a Tweet that looks like its being typed out in real time.',
    tags: [tags.popular, tags.video, tags.social],
  },
  {
    name: 'Smooth Scroller',
    id: '29462b8d-79a6-4611-ba2e-c1e61b849c02',
    slug: 'smooth-scroller',
    vendor: 'Floom',
    thumbnail: 'garyveetweet.png',
    videoThumbnail: 'products/smooth-scroller.mp4',
    cost: 0,
    monthlyCost: 0,
    live: true,
    description:
      'Create beautiful, 60 fps, HD scrolling video previews of your new content. Perfectly sized and ready to share on Instagram Stories and Facebook. ',
    tags: [tags.popular, tags.video, tags.social],
  },
  {
    name: 'One-Click Audiogram',
    id: 'ab1befcd-033e-41a4-9921-c34e930453b4',
    slug: 'podcast-trailer',
    videoThumbnail: 'products/podcast-trailer.mp4',
    vendor: 'wavve.co',
    thumbnail: 'speech-to-text.png',
    cost: 200,
    live: true,
    description:
      "You know how Instagram and Facebook don't let you upload audio. Yeah, this fixes that problem. Turn your audio into a video for sharing on social with one click.",
    tags: [tags.popular, tags.video, tags.social, tags.podcast],
  },
  {
    name: 'Wavve.co Templates',
    link: '/wavve-templates',
    vendor: 'Floom',
    // videoThumbnail: 'products/rise-podcast.mp4',
    thumbnail: 'products/wavve-templates.png',
    theme: 'orange-pink',
    cost: 0,
    live: true,
    tags: [tags.social, tags.podcast],
    description:
      'Create better looking Wavve videos in one-click. Check out our growing library of templates for creating professional and eye catching videos.',
  },
  {
    name: '#TwitterWrapped',
    slug: 'twitter-wrapped',
    id: 'b06c3cd1-d95d-4a1f-a215-0b19bfc38891',
    // videoThumbnail: 'https://s3.amazonaws.com/floom-resources/demo/how_I_built_this_five_guys_clip.mp4',
    vendor: 'wavve.co',
    thumbnail: 'products/twitter-wrapped.png',
    cost: 0,
    live: true,
    description:
      'Create a beautiful word cloud visualization of Tweets with Floom. Your unique word cloud highlights the language used in Tweets.',
    tags: [tags.popular, tags.social, tags.fun],
  },
  {
    name: 'One-Click Video Branding',
    slug: 'video-border',
    id: '1cd37b78-da29-43c6-b239-afa77738e78d',
    vendor: 'Floom',
    thumbnail: 'outdoor-border.png',
    videoThumbnail: 'products/border-lole.mp4',
    cost: 100,
    live: true,
    description:
      '2020 is the year of resharing. Make sure the videos you post have your brand associated with them by adding a logo or word mark.',
    tags: [tags.video, tags.social],
  },
  {
    name: 'One-Click Live Video Preview',
    slug: 'live-video-preview',
    id: '6bee6cd7-62e1-4035-b1b0-325604f5e453',
    vendor: 'Floom',
    thumbnail: 'outdoor-border.png',
    videoThumbnail: 'products/ocean-preview.mp4',
    cost: 0,
    live: true,
    description:
      "We'll pull 1 second clips from throughout your video and stitch them back together to create a preview of your video.",
    tags: [tags.video, tags.social],
  },
  {
    name: 'Audio Vocal Separator',
    slug: 'vocal-remover',
    id: 'f1242598-6162-4244-84b2-4c5e8510e882',
    vendor: 'Vocal Remover',
    thumbnail: 'products/vocal-remover.png',
    theme: 'purple-pink',
    cost: 399,
    live: true,
    tags: [tags.fun],
    description:
      "Vocal Remover's artificial intelligence powered algorithm processes your audio to separate the vocals from instrumentals. Great for musicians mixing music and for karaoke!",
  },
  /*{
    id: '9ce3e087-617a-4b38-a2ce-4b2fa5947841',
    name: 'One-Click Image Background Removal',
    slug: 'remove-bg',
    vendor: 'remove.bg',
    videoThumbnail: 'https://storage.floom.app/file/11250415-38ed-4bb5-836d-472c0e049cd8/May-13-2019 19-52-383.mp4',
    thumbnail: 'video-gif.gif',
    theme: 'blue-green',
    cost: 200,
    live: true,
    description:
      'Remove the background from your image to create a .png with transparency. 100% automatically – in 5 seconds – in one click',
  },
  {
    id: 'f1242598-6162-4244-84b2-4c5e8510e882',
    name: 'Audio Vocal Separator',
    slug: 'vocal-remover',
    vendor: 'Vocal Remover',
    thumbnail: 'speech-to-text.png',
    theme: 'purple-pink',
    cost: 399,
    live: true,
    description:
      "Vocal Remover's artificial intelligence powered algorithm processes your audio to separate the vocals from instrumentals. Great for musicians mixing music and for karaoke!",
  },
  // {
  //   id: 'b5ff3642-31b1-4b6c-98f5-3b0827d7a479',
  //   name: 'Hedgehog Greeting Services',
  //   slug: 'hedgehog-greeter',
  //   vendor: 'Floom',
  //   thumbnail: 'blooming.png',
  //   cost: 0,
  //   live: true,
  //   cta: 'Try it',
  //   description: 'A free "service" that gives you a sense of how easy Floom is to use. Oh, and say hi to your favorite hedgehog while you\'re at it',
  // },
  {
    id: 'b6261cadfs3-d2f8-4712-a772-d65f45f21a31',
    name: 'SaaS Report Card',
    slug: 'saas-report',
    vendor: 'Floom',
    thumbnail: 'mrr.png',
    theme: 'pink-purple',
    cost: 2500,
    live: true,
    description:
      'Turn your Stripe data into an interactive report on how your SaaS business is doing. Includes MRR, CLV, cohort and customer analysis.',
  },

  {
    id: '1cd37asdfb78-da29-43c6-b239-dafa77738e78d',
    name: 'One-Click Video Resize for IG Stories',
    slug: 'instagram-video-resize',
    vendor: 'Floom',
    thumbnail: 'outdoor-border.png',
    theme: 'pink-orange',
    videoThumbnail: 'https://s3.amazonaws.com/floom-border/f2c958e6-9f9a-4ca0-b070-9fbf94df1232.mp4',
    cost: 0,
    live: true,
    description:
      "Getting your videos the right size for Instagram is tricky. Floom is here to help. Upload your video and we'll size it perfectly for Instagram Stories and IGTV.",
  },
  {
    id: 'aa1befcd-rfq033e-41a4-9921-c34e930453b4',
    name: 'Audio Visualization',
    slug: 'podcast-trailer',
    videoThumbnail: 'https://s3.amazonaws.com/floom-resources/demo/how_I_built_this_five_guys_clip.mp4',
    vendor: 'wavve.co',
    thumbnail: 'speech-to-text.png',
    cost: 500,
    live: true,
    description:
      'Perfect for musicians and podcasters. Turn your audio into video for sharing on Instagram and Facebook. Powered by Wavve.co.',
  },
  {
    id: '1cd347bd78-da29-43c6-b239-afa77738e78d',
    name: 'One-Click Video Branding',
    slug: 'video-border',
    vendor: 'Floom',
    thumbnail: 'outdoor-border.png',
    theme: 'green-blue',
    videoThumbnail: 'https://s3.amazonaws.com/floom-resources/preview/VideoBorderWithLogoTrimmed.mp4',
    cost: 100,
    live: true,
    description: 'Get your videos ready to share by adding your branding with one click.',
  },
  {
    name: 'Wavve Templates',
    link: '/wavve-templates',
    vendor: 'Floom',
    videoThumbnail: 'https://s3.amazonaws.com/sls-wavve-out/f53f247f-f2dd-4f6c-b60a-99bdff684709.mp4',
    theme: 'orange-pink',
    cost: 0,
    live: true,
    description: 'Create better looking Wavve videos in one-click. Check out our growing library of templates.',
  },
  {
    id: '1cd347b78-da29-43c6-b239-dafa77738e78d',
    name: 'One-Click Tweet-to-Image',
    slug: 'tweet-to-image',
    vendor: 'Squawk.dev',
    thumbnail: 'garyveetweet.png',
    theme: 'purple-pink',
    cost: 0,
    live: true,
    description:
      'Perfect for boosting your Twitter following. Create a video of your tweet so that you can share it on Facebook and Instagram.',
  },
  {
    id: 'a35d07fb-4ae8-4b58-b665-0344de6a885b',
    name: 'One-Click Tweet-to-Video',
    slug: 'tweet-to-video',
    vendor: 'Squawk.dev',
    thumbnail: 'garyveetweet.png',
    videoThumbnail: 'https://s3.amazonaws.com/floom-resources/twitter-video/8242fc84-721b-4f20-9fd8-18e280fd8d23.mp4',
    theme: 'pink-purple',
    cost: 100,
    live: true,
    description:
      'Perfect for boosting your Twitter following. Create a of your tweet so that you can share it on Facebook and Instagram.',
  },
  {
    id: '11cd37b78-da29-43c6-b239-dafa77738e78d',
    name: 'One-Click Live Video Preview',
    slug: 'live-video-preview',
    vendor: 'Floom',
    thumbnail: 'outdoor-border.png',
    theme: 'aqua',
    videoThumbnail: 'https://s3.amazonaws.com/floom-preview/live/cff7ab56-00e9-4e62-960d-38397919fcf5.mp4',
    cost: 100,
    live: true,
    description:
      "We'll pull 1 second clips from throughout your video and stitch them back together to create a preview of your video.",
  },
  {
    id: 'e540e003-0665-4da4-b08d-3264cc7898de',
    name: 'One-Click GIF Video Preview',
    slug: 'video-preview',
    vendor: 'Floom',
    thumbnail: 'video-gif.gif',
    theme: 'orange-pink',
    cost: 100,
    live: true,
    description: "We'll pick out the most important frames from your video and turn them into a GIF.",
  },
  */
]

export const otherServices = [
  {
    id: '2',
    name: 'More One-Click Video Edits',
    vendor: 'Floom',
    thumbnail: 'sentiment-analysis.png',
    theme: 'pink-purple',
    cost: 0,
    live: false,
    description:
      'Get your videos polished and ready for instagram. Add a border or simple intros and outros in seconds.',
  },
  {
    id: '3',
    name: 'Geographic Data Visualization',
    vendor: 'Floom',
    thumbnail: 'business-location.png',
    theme: 'green-blue',
    cost: 1000,
    live: false,
    description:
      'Data visualization with geography is hard. Floom is here to make it easier with customizable visualizations built with D3js. Coming soon!',
  },
]
