<template>
  <div>
    <section class="section">
      <div class="container">
        <div
          class="has-border-radius-32 has-background-ice has-padding-vertical has-padding-horizontal"
        >
          <div class="has-text-centered">
            <h1
              class="is-size-1 has-margin-top has-text-weight-bold has-font-alternate"
            >The Best New Products</h1>
            <h2
              class="is-size-4 has-small-margin-top"
            >Explore, buy, and manage your subscriptions, all in the same place.</h2>
          </div>
          <div v-if="false" class="is-pulled-right has-large-margin-right has-large-margin-top">
            <el-button type="text" class="has-text-weight-semibold is-size-5-6">Learn More →</el-button>
            <div class="is-clearfix"></div>
          </div>
          <div v-else class="has-padding-vertical"></div>
        </div>
      </div>
    </section>
    <section class="section has-padding-vertical">
      <div class="container">
        <div class="columns is-mobile is-multiline">
          <div
            v-for="category in categories"
            :key="category.label"
            class="column has-text-centered is-2-desktop is-one-third-tablet is-half-mobile is-flex"
          >
            <div
              class="category"
              :class="{ selected: selectedCategory === category.value }"
              @click="selectedCategory = category.value"
            >
              <font-awesome-icon class="is-size-4 has-small-margin-vertical" :icon="category.icon"></font-awesome-icon>
              <h3 class="is-size-5-6 has-text-weight-bold has-line-height-115">{{ category.label }}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="columns is-multiline">
          <div
            class="column is-one-third-desktop is-half-tablet has-margin-top"
            v-for="service in displayedServices"
            :key="service.name"
          >
            <router-link :to="{ path: service.link ? service.link : `/service/${service.slug}` }">
              <el-card
                class="has-border-radius-16 has-cursor-pointer"
                shadow="hover"
                style="border: none"
              >
                <product-card
                  v-bind:name="service.name"
                  v-bind:description="service.description"
                  v-bind:vendor="service.vendor"
                  v-bind:thumbnail="service.thumbnail"
                  v-bind:videoThumbnail="service.videoThumbnail"
                  v-bind:cost="service.cost"
                  v-bind:monthlyCost="service.monthlyCost"
                  v-bind:tags="service.tags"
                ></product-card>
              </el-card>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <br />
    <section class="section has-large-margin-vertical">
      <div
        class="container has-text-centered has-large-padding-vertical has-border-radius-32 has-background-white-ter"
      >
        <h2 class="is-size-1-2 has-font-alternate has-small-padding-top">Building something amazing?</h2>
        <p class="has-small-margin-bottom has-small-margin-top is-size-5">We want to hear about it.</p>
        <br />
        <a href="/launch">
          <el-button type="default">Learn More</el-button>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import ServiceCard from '@/components/ServiceCard.vue'
import ProductCard from '@/components/ProductCard.vue'
import { faHeart, faFire, faMicrophone, faVideo, faGrinWink, faSearch } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'home',
  components: {
    ServiceCard,
    ProductCard,
  },
  data() {
    return {
      categories: [
        { label: 'Everything', icon: faSearch, value: 'all' },
        { label: 'Best Selling', icon: faFire, value: 'popular' },
        { label: 'Social Media Growth', icon: faHeart, value: 'social' },
        { label: 'One-Click Videos', icon: faVideo, value: 'video' },
        { label: 'Just for Podcasters', icon: faMicrophone, value: 'podcast' },
        { label: 'Just for Fun', icon: faGrinWink, value: 'fun' },
      ],
      selectedCategory: 'popular',
      twitterVideoItems: [
        'https://s3.amazonaws.com/floom-resources/twitter-video/8410963e-b5dd-41e8-829d-501f975f1760.mp4',
        'https://storage.floom.app/file/d0afe58c-8edf-4d8a-8fd9-90296927cf53/video.mp4',
      ],
      twitterCloudItems: [
        'https://s3.amazonaws.com/floom-resources/twitter-cloud/5bf46aa0-d658-4166-ad21-cc0f9ea9ae25.png',
        'https://s3.amazonaws.com/floom-resources/twitter-cloud/3fa8acbf-b059-42ee-ab0d-3f25179ea6a5/cloud.png',
        'https://s3.amazonaws.com/floom-resources/twitter-cloud/8037282f-a978-42a7-8b7e-f5c755a3b5ff/cloud.png',
      ],
    }
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  computed: {
    featuredServices() {
      return this.$store.state.featuredServices
    },
    displayedServices() {
      if (this.selectedCategory === 'all') {
        return this.featuredServices
      } else {
        return this.featuredServices
          .filter((s) => s.tags.includes(this.selectedCategory))
          .sort((a, b) => a.tags.length - b.tags.length)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/helpers.scss';
.category {
  @extend .has-border-radius-16, .has-background-lavender-light, .has-padding-all, .is-full-width, .has-cursor-pointer;
  transition: all 0.3s;
  &:hover {
    @extend .has-background-lavender;
    box-shadow: 0 0px 15px #e9e5fc;
  }
  &.selected {
    @extend .has-background-lavender-dark;
  }
}

.headline-video {
  max-height: 450px;
}

.has-background-white-light {
  background-image: linear-gradient(180deg, #fff, #fff 10%, #e3ddf86e 10%, #e3ddf82e 100%);
}
</style>
