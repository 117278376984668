<template>
<div>
  <div v-bind:class="`card__ceiling card--${color}`"></div>
  <div class="card__cover">
    <video v-if="videoThumbnail" :src="videoThumbnail"
      preload="true" playsinline="true" autoplay="true" loop="true" muted="muted"
    >
    </video>
    <img v-else
    :src="require(`@/assets/${thumbnail}`)"
    :alt="name"
    >
  </div>
  <p class="spaced">Provided by {{ vendor }} </p>
  <h4 class="is-size-5"> {{ name }}  </h4>
  <p class="is-size-6-7">{{ description }} </p>
  <router-link v-if="live" :to="link ? link : `/service/${(slug || id)}`">
    <el-button class="has-margin-top is-uppercase" type="primary" size="medium">{{ cta || "VIEW" }}</el-button>
  </router-link>
  <el-button v-else type="text">COMING SOON</el-button>
</div>
</template>


<script>

export default {
  name: 'ServiceCard',
  props: {
    name: String,
    link: String,
    slug: String,
    vendor: String,
    description: String,
    color: String,
    id: String,
    thumbnail: String,
    videoThumbnail: String,
    live: Boolean,
    cta: String,
  },
}
</script>

<style lang="scss" scoped>
.card__ceiling {
  width: 100%;
  height: 4px;
  margin-top:20px;
  margin-bottom: 24px;
  background-color:#cbd0df;
}
.card--orange-pink {
  background-image: linear-gradient(135deg, #ff9f2e, #f723c4)
}
.card--pink-orange {
  background-image: linear-gradient(135deg, #f723c4, #ff9f2e)
}
.card--pink-purple {
  background-image: linear-gradient(135deg, #ff4dae, #4268ff)
}
.card--purple-pink {
  background-image: linear-gradient(135deg, #4268ff, #ff4dae)
}
.card--green-blue {
  background-image: linear-gradient(135deg, #00f2aa, #0083ff)
}
.card--aqua {
  background-image: linear-gradient(135deg, #00e1ff, #00f2aa)
}
.card--blue-green {
  background-image: linear-gradient(135deg, #0083ff, #00f2aa)
}
.card__cover {
  text-align: center;
  img {
    height: 200px;
    margin: 0 auto 12px;
  }
  video {
    height: 200px;
    margin: 0 auto 12px;
  }
}
p.spaced {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 9px;
}
</style>
