import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import VueGtm from 'vue-gtm'
import { library } from '@fortawesome/fontawesome-svg-core'
// import { faHeart, faFire, faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin, AmplifyEventBus } from 'aws-amplify-vue'
import VueClipboard from 'vue-clipboard2'
import VueProgressBar from 'vue-progressbar'
import VueScrollTo from 'vue-scrollto'
import EventBus from './helpers/event-bus'
import amplifyConfig from './amplify-config'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element/element'
import './plugins/element/element-variables.scss'
import './style/main.scss'
import titleMixin from './mixins/titleMixin'
import { getMetatagAttribute } from './helpers/utils'

// library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Sentry.init({
  dsn: 'https://9f689c6e5e0d433390def43682d29901@sentry.io/1863414',
  integrations: [new SentryIntegrations.Vue({ Vue, attachProps: true, logErrors: true })],
})

// prod or dev
const amplifyEnvMap = {
  development: 'dev',
  production: 'NONE',
}
const backendEnv = process.env.VUE_APP_BACKEND_ENV || process.env.NODE_ENV || 'development'
const amplifyEnv = amplifyEnvMap[backendEnv]

Amplify.configure(amplifyConfig[amplifyEnv])
AmplifyModules.Auth.configure({ oauth: amplifyConfig[amplifyEnv].oauth })
Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(VueScrollTo)
Vue.use(VueClipboard) // for copying text to user clipboard
Vue.use(VueProgressBar, {
  color: 'rgb(92, 37, 199)',
  failedColor: 'rgb(216, 32, 56)',
  height: '3px',
})

const isProd = process.env.NODE_ENV === 'production'
Vue.use(VueAnalytics, {
  id: 'UA-138427955-1',
  router,
  debug: {
    enabled: false, // !isProd,
    sendHitTask: isProd,
  },
})
Vue.use(VueGtm, {
  id: 'GTM-55QWL7D', // Your GTM ID
  // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  enabled: isProd,
  debug: !isProd, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // If router, you can exclude some routes name (case insensitive) (optional)
})

// if (window.heap) {
//   if (isProd) {
//     window.heap.load('2921331126')
//   } else {
//     window.heap.load('2115184947')
//   }
// }

Vue.mixin(titleMixin)
Vue.config.productionTip = false

const getUser = async () => {
  try {
    const data = await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    if (data && data.signInUserSession) {
      store.commit('setUser', data)
      return data
    }
    return null
  } catch (e) {
    store.commit('setUser', null)
    console.log('Error setting user:', e)
    return null
  }
}

getUser()

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const user = await getUser()
    let path = '/auth'
    if (from.name === 'home') {
      path = '/auth/sign-up'
    }
    if (!user) {
      return next({
        path,
        query: {
          redirect: to.fullPath,
        },
      })
    }
    if (to.matched.some((record) => record.meta.adminOnly)) {
      if (!(user.signInUserSession.idToken.payload['cognito:groups'] || []).includes('admin')) {
        return next({
          path: '/no-access',
          // query: {
          //   redirect: from.fullPath,
          // },
        })
      }
    }
    if (to.matched.some((record) => record.meta.vendorOnly)) {
      if (!(user.signInUserSession.idToken.payload['cognito:groups'] || []).includes('vendor')) {
        return next({
          path: '/no-access',
        })
      }
    }
    return next()
  }
  return next()
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Floom'
  if (to.meta && to.meta.metatags) {
    Object.keys(to.meta.metatags).forEach((tag) => {
      const attribute = getMetatagAttribute(tag)
      const oldNodes = [...document.querySelectorAll(`[${attribute}="${tag}"]`)]
      oldNodes.forEach((n) => n && n.remove())
      const newTag = document.createElement('meta')
      newTag.setAttribute(attribute, tag)
      newTag.setAttribute('content', to.meta.metatags[tag])
      document.head.appendChild(newTag)
    })
  }
  next()
})

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut') {
    store.commit('setUser', null)
    console.log('redirecting to /auth')
    router.push({
      path: '/auth',
    })
    EventBus.$emit('heapResetIdentity')
  } else if (state === 'signedIn') {
    await getUser()
    EventBus.$emit('heapInitializeUser')
    if (router.currentRoute.query.redirect) {
      router.push(router.currentRoute.query.redirect)
    } else if (router.currentRoute.query.afterauth) {
      router.push(router.currentRoute.query.afterauth)
      router.go()
    } else {
      router.push('/')
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  watch: {
    $route() {
      EventBus.$emit('heapSetLastSeen')
    },
  },
}).$mount('#app')
