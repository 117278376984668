<template>
  <nav class="navbar is-spaced" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <router-link class="navbar-item" :to="{ name: 'home' }">
          <img src="@/assets/circle_logo_transparent128.png" alt="Floom Logo" />
          <!-- <img src="@/assets/Hummingbird@0.5x.png" alt="Floom Logo"> -->
        </router-link>
        <a
          id="navbarMenuBurger"
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" id="navbarMenu">
        <div class="navbar-start">
          <router-link v-if="isAdmin" :to="{ name: 'market' }" class="navbar-item">Market</router-link>
          <router-link v-if="!isAdmin" :to="{ name: 'home' }" class="navbar-item">Market</router-link>
          <router-link :to="{ name: 'launch' }" class="navbar-item">Launch Your Product</router-link>
          <!-- <a href="https://blog.floom.app" target="_blank" class="navbar-item">Blog</a> -->
          <a href="https://docs.floom.app" target="_blank" class="navbar-item">API Docs</a>
          <router-link v-if="isAdmin" :to="{ name: 'serviceNew' }" class="navbar-item">Create a Service</router-link>
        </div>
        <div class="navbar-end">
          <router-link :to="{ name: 'orders' }" class="navbar-item">Your Orders</router-link>
          <div v-if="user" class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              Account
              <!-- <figure class="image is-32x32">
                <img class="is-rounded" src="https://bulma.io/images/placeholders/32x32.png">
              </figure>-->
            </a>

            <div class="navbar-dropdown">
              <router-link :to="{ name: 'account' }" class="navbar-item">Account</router-link>
              <router-link :to="{ name: 'orders' }" class="navbar-item">Order History</router-link>
              <router-link :to="{ name: 'subscriptions' }" class="navbar-item">Subscriptions</router-link>
              <div v-if="isVendor">
                <hr class="navbar-divider" />
                <router-link :to="{ name: 'services' }" class="navbar-item">Your Services</router-link>
                <router-link :to="{ name: 'sellerSubscriptions' }" class="navbar-item"
                  >Seller Subscriptions</router-link
                >
                <router-link :to="{ name: 'sellerOrders' }" class="navbar-item">Seller History</router-link>
              </div>
              <auth-sign-out class="navbar-item"></auth-sign-out>
            </div>
          </div>
          <div v-else class="navbar-item">
            <router-link :to="{ name: 'auth' }">
              <el-button>Sign In</el-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import AuthSignOut from '@/components/AuthSignOut.vue'

export default {
  name: 'TheNavbar',
  components: {
    AuthSignOut,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isVendor() {
      return this.user && (this.user.signInUserSession.idToken.payload['cognito:groups'] || []).includes('vendor')
    },
    isAdmin() {
      return this.user && (this.user.signInUserSession.idToken.payload['cognito:groups'] || []).includes('admin')
    },
  },
  mounted() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          const { target } = el.dataset
          const $target = document.getElementById(target)
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active')
          $target.classList.toggle('is-active')
        })
      })
    }
  },
  watch: {
    // eslint-disable-next-line
    $route() {
      const elements = [document.getElementById('navbarMenu'), document.getElementById('navbarMenuBurger')]
      elements.forEach((el) => {
        if (el) {
          el.classList.remove('is-active')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar-item {
  font-weight: 500;
  color: #434343;
  font-size: 0.95em;
}
</style>
