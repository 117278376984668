<template>
  <div id="app">
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
    <the-navbar v-if="!['auth'].includes($route.name)" />
    <router-view class="router-view-container"></router-view>
    <the-footer
      v-if="!['auth', 'order'].includes($route.name)"
      :dark="['about', 'account', 'serviceEdit', 'launch'].includes($route.name)"
    ></the-footer>
  </div>
</template>

<script>
import { Hub, Auth } from 'aws-amplify'
import EventBus from '@/helpers/event-bus'
import TheNavbar from '@/components/TheNavbar.vue'
import TheFooter from '@/components/TheFooter.vue'

export default {
  name: 'app',
  components: {
    TheNavbar,
    TheFooter,
  },
  mounted() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log({ event, data })
      if (event === 'signIn') {
        this.$store.commit('setUser', data)
      }
      if (event === 'signOut') {
        this.$store.commit('setUser', null)
      }
      if (event === 'signIn_failure') {
        this.$store.commit('setUser', null)
        // setTimeout(() => {
        //   if (this.$store.state.user === null) {
        //     Auth.federatedSignIn({ provider: 'Google' })
        //   }
        // }, 1000)
      }
    })
    // Handle Event Tracking
    EventBus.$on('addUserProperties', (properties) => this.addHeapUserProperties(properties))
    EventBus.$on('heapInitializeUser', this.heapInitializeUser)
    EventBus.$on('heapResetIdentity', this.heapResetIdentity)
    EventBus.$on('heapSetLastSeen', this.heapSetLastSeen)
    this.heapInitializeUser()
  },
  methods: {
    heapResetIdentity() {
      if (window.heap) {
        window.heap.resetIdentity()
      }
    },
    addHeapUserProperties(properties) {
      if (window.heap) {
        window.heap.addUserProperties(properties)
      }
    },
    heapInitializeUser() {
      const userData = this.getAnalyticsData()
      if (userData) {
        if (window.heap) {
          window.heap.identify(userData.user_id)
        }
        this.addHeapUserProperties(userData)
      }
    },
    heapSetLastSeen() {
      this.addHeapUserProperties({
        last_seen: parseInt(new Date().getTime() / 1000, 10),
      })
    },
    getAnalyticsData() {
      if (this.$store.state.user) {
        const userData = {
          email: this.$store.state.user.attributes.email,
          user_id: this.$store.state.user.username,
        }
        if (this.$store.state.user.attributes['custom:stripeid']) {
          userData.stripeCustomerId = this.$store.state.user.attributes['custom:stripeid']
        }
        return { ...userData }
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.router-view-container {
  min-height: calc(100vh - 84px - 169px);
}
#app {
  font-family: 'Nunito Sans', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}

.el-table {
  .cell {
    word-break: normal !important;
  }
}

// AWS Authenticator Styling
:root {
  --amazonOrange: rgb(92, 38, 199) !important;
  --lightAmazonOrange: rgb(92, 38, 199, 0.9) !important;
  --darkAmazonOrange: rgb(68, 18, 167) !important;
}

.authenticator {
  button {
    border-radius: 4px !important;
  }
}
</style>
