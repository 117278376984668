<template>
  <div>
    <el-button v-on:click="signOut">Sign Out</el-button>
    <div class="error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue';

export default {
  name: 'AuthSignOut',
  props: ['signOutConfig'],
  data() {
    return {
      error: '',
      show: false,
      logger: {},
    }
  },
  async mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
  },
  methods: {
    signOut() {
      this.$Amplify.Auth.signOut()
        .then(() => {
          this.logger.info('signout success')
          return AmplifyEventBus.$emit('authState', 'signedOut')
        })
        .catch(e => this.setError(e));
    },
    setError(e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error)
    },
  },
}
</script>
