import Vue from 'vue';
import Vuex from 'vuex';
import {
  featuredServices,
  otherServices
} from './data/services'

Vue.use(Vuex);

const devStripeEnv = 'live' // live or test
export default new Vuex.Store({
  state: {
    featuredServices,
    otherServices,
    user: null,
    env: process.env.NODE_ENV,
    isProd: process.env.NODE_ENV === 'production',
    stripeEnv: process.env.NODE_ENV === 'production' ? 'live' : devStripeEnv,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },
})
