<template>
  <div>
    <div class="card__cover">
      <video
        v-if="videoThumbnail"
        :src="require(`@/assets/${videoThumbnail}`)"
        preload="true"
        playsinline="true"
        autoplay="true"
        loop="true"
        muted="muted"
        class="has-border-radius-16"
      ></video>
      <el-image
        v-else
        :src="require(`@/assets/${thumbnail}`)"
        :alt="name"
        fit="scale-down"
        class="has-border-radius-16"
      ></el-image>
      <div class="card__price">
        <div v-if="monthlyCost">
          <p>
            <span class="has-text-weight-bold is-size-5-6">{{ serviceMonthlyAmountString(monthlyCost) }}</span>
            <span class="has-text-grey-dark" style="margin-left:2px;margin-right:1px;">/</span>
            <span class="has-text-grey-dark">month</span>
          </p>
        </div>
        <div v-else>
          <p class="has-text-weight-bold is-size-5-6">{{ serviceAmountString(cost) }}</p>
        </div>
      </div>
    </div>
    <h4 class="is-size-4-5 has-text-weight-bold has-margin-top">{{ name }}</h4>
    <p class="is-size-6-7 has-small-margin-top">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: {
    name: String,
    vendor: String,
    description: String,
    thumbnail: String,
    videoThumbnail: String,
    cost: Number,
    monthlyCost: Number,
    tags: Array,
  },
  methods: {
    serviceAmountString(amount) {
      if (amount && typeof amount === 'number') {
        if (amount === 0) {
          return 'Free'
        } else if (Math.floor(amount / 100) === amount / 100) {
          return `From $${(amount / 100).toFixed(0)}`
        }
        return `From $${(amount / 100).toFixed(2)}`
      }
      return 'Free'
    },
    serviceMonthlyAmountString(amount) {
      if (amount && typeof amount === 'number') {
        if (amount === 0) {
          return 'Free'
        } else if (Math.floor(amount / 100) === amount / 100) {
          return `$${(amount / 100).toFixed(0)}`
        }
        return `$${(amount / 100).toFixed(2)}`
      }
      return 'Free'
    },
  },
}
</script>

<style lang="scss" scoped>
p.spaced {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 9px;
}
.card__cover {
  position: relative;
}
.card__price {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 16px 0 0 0;
  background-color: rgba(256, 256, 256, 1);
  padding: 0.5em 1em;
}
</style>
